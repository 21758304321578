import React from "react";

import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import MotorcycleIcon from "@mui/icons-material/TwoWheeler";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";

import { HMIDeviceConfiguration } from "./hmi-device-configuration";
import { HMIDeviceState } from "./hmi-device-state";
import { HMIDeviceTelemetry } from "./hmi-device-telemetry";
import { VehicleOwners } from "./vehicle-owners";
import { useTheme } from "@mui/material/styles";
import { TextField as MuiTextField } from "@mui/material/";

const colors = {
  brand: {
    green: "#008675",
    green_medium: "#006C5B",
    green_dark: "#004C45",
    yellow: "#FFD100",
    red: "#EF3340",
    grey: "#D9E1E2",
  },
};

const ErrorFallback = ({ error }) => {
  return (
    <Alert severity="error">
      Something went wrong:
      <br />
      <pre sx={{ color: "red" }}>{error.message}</pre>
    </Alert>
  );
};

const VehicleChip = (props) => (
  <Chip icon={<MotorcycleIcon />} color="primary" {...props} />
);

const CustomerChip = (props) => (
  <Chip icon={<PersonIcon />} color="primary" {...props} />
);

const PageTitle = ({ children }) => {
  return (
    <Typography
      component="h1"
      variant="h4"
      noWrap
      gutterBottom
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children}
    </Typography>
  );
};

const CardTitle = ({ children }) => (
  <Typography variant="h5" component="h2" gutterBottom>
    {children}
  </Typography>
);

const CardSubTitle = ({ children }) => (
  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
    {children}
  </Typography>
);

const CardAlert = ({ children }) => {
  const theme = useTheme();
  return (
    <Alert
      severity="error"
      sx={{
        margin: theme.spacing(0, 0, 3, 0),
      }}
    >
      {children}
    </Alert>
  );
};

const CardSection = ({ children }) => {
  const theme = useTheme();
  return <div style={{ margin: theme.spacing(1) }}>{children}</div>;
};

const LoadingButton = ({ children, ...props }) => {
  const { loading, ...rest } = props;

  return (
    <div sx={{ position: "relative" }}>
      <Button disabled={props.loading} {...rest}>
        {children}
      </Button>
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  );
};

const ModalTitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      component="h1"
      variant="h5"
      noWrap
      sx={{ margin: theme.spacing(0, 0, 4, 0) }}
    >
      {children}
    </Typography>
  );
};

const FullPageSpinner = () => {
  return (
    <div
      sx={{
        fontSize: "4em",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const tabStyle = other.fullWidth ? "full-width" : "simple";
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabStyle}-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const TextField = ({ name, editing, value, required, onChange, error }) => {
  const theme = useTheme();
  const formatLabel = (name) => {
    return name.split("_").map(capitalizeFirstLetter).join(" ");
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <MuiTextField
      id={name}
      name={name}
      label={name && formatLabel(name)}
      value={value}
      onChange={onChange}
      fullWidth
      sx={{
        margin: theme.spacing(0, 0, 3, 0),
        "&:last-child": {
          margin: 0,
        },
      }}
      InputProps={{
        readOnly: !editing,
      }}
      required={required}
      error={error}
    />
  );
};

export {
  CardAlert,
  CardTitle,
  CardSubTitle,
  CardSection,
  colors,
  CustomerChip,
  ErrorFallback,
  FullPageSpinner,
  HMIDeviceConfiguration,
  HMIDeviceState,
  HMIDeviceTelemetry,
  LoadingButton,
  PageTitle,
  ModalTitle,
  TabPanel,
  TextField,
  VehicleChip,
  VehicleOwners,
};
