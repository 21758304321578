import React from "react";
import moment from "moment";
import useSWR from "swr";
import { fleetmanagerFetcher } from "../api";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CardAlert, CardTitle, CardSubTitle, CardSection } from "./lib";
import { useMobileLayout } from "../hooks/use-mobile-layout";

import { useHMIDevice } from "../data/use-hmi-device";

const DataPoint = ({ deviceID, name, title }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { data, error } = useSWR(
    `/hmi_devices/${deviceID}/point/${name}`,
    fleetmanagerFetcher
  );

  React.useEffect(() => {
    setIsLoading(!error && !data);
  }, [data, error]);

  return (
    <CardSection>
      <Typography
        color="textSecondary"
        title={data ? `Updated ${moment(data.time).fromNow()}` : title}
      >
        {title}
      </Typography>
      <Typography variant="h5" component="h2">
        {isLoading ? (
          <Skeleton variant="text" />
        ) : data ? (
          `${data.value}${data.unit}`
        ) : (
          "-"
        )}
      </Typography>
    </CardSection>
  );
};

const MilDataPoint = ({ deviceID }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { data, error } = useSWR(
    `/hmi_devices/${deviceID}/point/MIL`,
    fleetmanagerFetcher
  );

  React.useEffect(() => {
    setIsLoading(!error && !data);
  }, [data, error]);

  return (
    <>
      {data && data.value === true &&
        <CardSection>
          <Typography
            color="textSecondary"
            title={data ? `Updated ${moment(data.time).fromNow()}` : "MIL"}
          >
            MIL
          </Typography>
          <Typography variant="h5" component="h2">
            {isLoading ? (
              <Skeleton variant="text" />
            ) : `On`
            }
          </Typography>
        </CardSection>
      }
    </>
  );
}

const Position = ({ deviceID }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { data, error } = useSWR(
    `/hmi_devices/${deviceID}/position`,
    fleetmanagerFetcher
  );

  React.useEffect(() => {
    setIsLoading(!error && !data);
  }, [data, error]);

  return (
    <CardSection>
      <Typography
        color="textSecondary"
        title={data ? `Updated ${moment(data.time).fromNow()}` : "Position"}
      >
        Last position
      </Typography>
      <Typography variant="h5" component="h2">
        {isLoading ? (
          <Skeleton variant="text" />
        ) : data ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`http://www.google.com/maps/place/${data.lat},${data.lng}`}
          >
            [{data.lat}, {data.lng}]
          </Link>
        ) : (
          "-"
        )}
      </Typography>
    </CardSection>
  );
};

export const HMIDeviceTelemetry = ({ deviceID }) => {
  const [lastSeenAt, setLastSeenAt] = React.useState(null);
  const { isMobile } = useMobileLayout();

  const { device, error: deviceError, isLoading } = useHMIDevice(deviceID);
  const [sendsPos, setSendsPos] = React.useState(null);

  React.useEffect(() => {
    try {
      const cfg = JSON.parse(atob(device.config.binaryData));
      setSendsPos(cfg.send_position);
    } catch { }
  }, [device]);

  React.useEffect(() => {
    try {
      const lastEventTime = moment(device.lastEventTime);
      const lastHeartbeatTime = moment(device.lastHeartbeatTime);
      setLastSeenAt(
        lastEventTime.isAfter(lastHeartbeatTime)
          ? lastEventTime
          : lastHeartbeatTime
      );
    } catch { }
  }, [device]);

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <CardTitle>Telemetry</CardTitle>
        <CardSubTitle>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : lastSeenAt ? (
            `Last seen ${lastSeenAt.fromNow()}`
          ) : (
            "Never seen"
          )}
        </CardSubTitle>

        {deviceError && <CardAlert>{deviceError.message}</CardAlert>}
        <div style={isMobile ? { flexDirection: "column" } : { display: "flex" }}>

          <DataPoint deviceID={deviceID} name="Odometer" title="Odometer" />
          <DataPoint deviceID={deviceID} name="BatteryLevel" title="Charge" />
          <MilDataPoint deviceID={deviceID} />
          <DataPoint
            deviceID={deviceID}
            name="BCU_Version"
            title="VCU Version"
          />
          {sendsPos && <Position deviceID={deviceID} />}
        </div>
      </CardContent>
    </Card>
  );
};
