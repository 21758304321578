import useSWR from "swr";
import { fleetmanagerFetcher } from "../api";

export const useVehicleOwners = (vehicleID) => {
  if (!vehicleID) {
    throw new Error("missing vehicle ID");
  }

  const { data, error, mutate } = useSWR(
    `/vehicles/${vehicleID}/owners`,
    fleetmanagerFetcher
  );

  return {
    isLoading: !error && !data,
    owners: data,
    error: error,
    mutate: mutate,
  };
};
