import React from "react";
import moment from "moment";
import { Alert } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { useHMIDevice } from "../data/use-hmi-device";
import { saveDeviceConfig } from "../api";
import {
  CardAlert,
  CardTitle,
  CardSubTitle,
  CardSection,
  LoadingButton,
} from "./lib";
import { getWheelDiaFromVin } from "../util/vin_validator";
import { useCallback } from "react";

const logLevels = ["error", "info", "debug"];
const defaultConfiguration = {
  developer: true,
  log_level: "info",
  vin: "",
  wheel_dia: 0,
  generation: "",
  force_mil_off: false,
  export_bms_data: false,
  use_test_env: false,
  allow_key_pairing: false,
};

export const HMIDeviceConfiguration = ({
  deviceID,
  title = "Device configuration",
  vin = "00000000000000000",
  generation = "",
  forceConfigUpdate = false,
}) => {
  const [deviceConfig, setDeviceConfig] = React.useState({
    ...defaultConfiguration,
  });
  const [isDirty, setIsDirty] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saveError, setSaveError] = React.useState(null);

  const {
    device,
    error: deviceError,
    isLoading,
    mutate,
  } = useHMIDevice(deviceID);

  const [configUpdatedAt, setConfigUpdatedAt] = React.useState(null);
  const [deviceHasLastConfig, setDeviceHasLastConfig] = React.useState(true);

  React.useEffect(() => {
    try {
      setConfigUpdatedAt(moment(device.config.cloudUpdateTime));
    } catch {}
  }, [device]);

  React.useEffect(() => {
    try {
      const deviceConfigTime = moment(device.lastConfigAckTime);
      setDeviceHasLastConfig(deviceConfigTime.isAfter(configUpdatedAt));
    } catch {}
  }, [device, configUpdatedAt]);

  // Attempt to use existing config on mount
  React.useEffect(() => {
    try {
      const cfg = JSON.parse(atob(device.config.binaryData));
      setDeviceConfig((prevDeviceConfig) => ({
        ...prevDeviceConfig,
        ...cfg,
      }));
    } catch {}
  }, [device]);

  const handleForceSaveConfig = useCallback(async () => {
    if (deviceConfig.vin !== vin || deviceConfig.generation !== generation) {
      const newConfig = {
        ...deviceConfig,
        vin: vin,
        wheel_dia: getWheelDiaFromVin(vin),
        generation: generation,
      };
      setDeviceConfig(newConfig);
      try {
        await saveDeviceConfig(device.id, newConfig);
        mutate();
      } catch (err) {
        setSaveError("Could not update config: " + err);
      }
    }
  }, [device, deviceConfig, generation, mutate, vin]);

  React.useEffect(() => {
    if (forceConfigUpdate) {
      handleForceSaveConfig();
    }
  }, [forceConfigUpdate, handleForceSaveConfig]);

  const handleSetDeveloper = (event) => {
    event.preventDefault();
    setDeviceConfig({
      ...deviceConfig,
      developer: event.target.checked,
    });
    setIsDirty(true);
  };

  const handleSetLogLevel = (event) => {
    setDeviceConfig({
      ...deviceConfig,
      log_level: event.target.value,
    });
    setIsDirty(true);
  };

  const handleSetForceMilOff = (event) => {
    event.preventDefault();
    setDeviceConfig({
      ...deviceConfig,
      force_mil_off: event.target.checked,
    });
    setIsDirty(true);
  };

  const handleSetAllowKeyPairing = (event) => {
    event.preventDefault();
    setDeviceConfig({
      ...deviceConfig,
      allow_key_pairing: event.target.checked,
    });
    setIsDirty(true);
  };

  const handleSetExportBMSData = (event) => {
    event.preventDefault();
    setDeviceConfig({
      ...deviceConfig,
      export_bms_data: event.target.checked,
    });
    setIsDirty(true);
  };

  const handleSetTestEnv = (event) => {
    event.preventDefault();
    setDeviceConfig({
      ...deviceConfig,
      use_test_env: event.target.checked,
    });
    setIsDirty(true);
  };

  const handleClickSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSaveError(null);
    try {
      await saveDeviceConfig(device.id, deviceConfig);
      setLoading(false);
      setIsDirty(false);
      // Manually revalidate the device
      mutate();
    } catch (err) {
      if (err.body) {
        const errObj = await err.json();
        setSaveError(errObj.message);
      } else {
        setSaveError("Something went wrong");
      }
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            configUpdatedAt && `Changed ${configUpdatedAt.fromNow()}`
          )}
        </CardSubTitle>
        {saveError !== null && <CardAlert>{saveError}</CardAlert>}
        {process.env.REACT_APP_BUILD_ENV === "PROD" &&
          deviceConfig.use_test_env && (
            <Alert severity="warning">
              Vehicle is not connected to this environment. Visit{" "}
              <Link href="https://madeleine-ec74c-test.firebaseapp.com/vehicles">
                TEST
              </Link>
              to change any configuration.
            </Alert>
          )}
        {process.env.REACT_APP_BUILD_ENV === "TEST" &&
          !deviceConfig.use_test_env && (
            <Alert severity="warning">
              Vehicle is not connected to this environment. Visit{" "}
              <Link href="https://portal.rgnt-motorcycles.com/vehicles">
                PROD
              </Link>{" "}
              to change any configuration.
            </Alert>
          )}
        {process.env.NODE_ENV === "development" && (
          <Alert severity="warning">
            {`Vehicle currently connected to ${
              deviceConfig.use_test_env ? "TEST" : "PROD"
            } environment.`}
          </Alert>
        )}
        {!deviceHasLastConfig && (
          <Alert severity="info">Config update in progress...</Alert>
        )}
        {deviceError ? (
          <CardAlert>{deviceError.message}</CardAlert>
        ) : (
          <>
            <CardSection>
              {/* Don't show vehicle config if vin is not passed (hmi info is shown without connection to vehicle) */}
              {vin && (
                <div style={{ display: "flex" }}>
                  <CardSection>
                    <Typography color="textSecondary">VIN</Typography>
                    <Typography>{deviceConfig.vin}</Typography>
                  </CardSection>
                  <CardSection>
                    <Typography color="textSecondary">
                      Wheel Diameter
                    </Typography>
                    <Typography>{deviceConfig.wheel_dia}</Typography>
                  </CardSection>
                  <CardSection>
                    <Typography color="textSecondary">Generation</Typography>
                    <Typography>{deviceConfig.generation}</Typography>
                  </CardSection>
                </div>
              )}
            </CardSection>
            <CardSection>
              <FormControlLabel
                control={
                  <Switch
                    checked={deviceConfig.developer}
                    onChange={handleSetDeveloper}
                    name="developer"
                  />
                }
                label="Developer"
              />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Advanced</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textSecondary">
                      For After Sales: Send a command to turn off MIL each time
                      HMI boots
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deviceConfig.force_mil_off}
                          onChange={handleSetForceMilOff}
                          name="forceMilOff"
                        />
                      }
                      label="Force MIL off"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textSecondary">
                      For After Sales: Turn on key pairing option on HMI.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deviceConfig.allow_key_pairing}
                          onChange={handleSetAllowKeyPairing}
                          name="allowKeyPairing"
                        />
                      }
                      label="Allow key pairing"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textSecondary">
                      Turn on to export battery data to Nortical (VIN must be
                      configured above)
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deviceConfig.export_bms_data}
                          onChange={handleSetExportBMSData}
                          name="exportBMSData"
                        />
                      }
                      label="Export data to Nortical"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textSecondary">
                      Turn on to move device to test environment
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deviceConfig.use_test_env}
                          onChange={handleSetTestEnv}
                          name="useTestEnv"
                        />
                      }
                      label="Use test environment"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textSecondary">
                      Select log level for cloud logs ('info' is default)
                    </Typography>
                    <div>
                      <FormControl>
                        <InputLabel>Log Level</InputLabel>
                        <Select
                          value={deviceConfig.log_level}
                          onChange={handleSetLogLevel}
                        >
                          {logLevels.map((rc) => (
                            <MenuItem key={rc} value={rc}>
                              {rc}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </CardSection>
          </>
        )}
      </CardContent>
      {isDirty && (
        <CardActions>
          <LoadingButton
            type="submit"
            onClick={(e) => handleClickSave(e)}
            variant="contained"
            color="primary"
            loading={loading}
          >
            Save
          </LoadingButton>
        </CardActions>
      )}
    </Card>
  );
};
