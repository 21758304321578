import useSWR from "swr";
import { fleetmanagerFetcher } from "../api";

export const useHMIDevice = (deviceID) => {
  if (!deviceID) {
    throw new Error("missing device ID");
  }

  const { data, error, mutate } = useSWR(
    `/hmi_devices/${deviceID}`,
    fleetmanagerFetcher
  );

  return {
    isLoading: !error && !data,
    device: data,
    error: error,
    mutate: mutate,
  };
};
