import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./auth-context";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});
const drawerWidth = 300;

function AppProviders({ children }) {
  const [cookies, setCookie] = useCookies(["theme"]);
  const [mode, setMode] = React.useState(
    cookies.theme ? cookies.theme : "light"
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setCookie("theme", mode === "light" ? "dark" : "light", { path: "/" });
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode, setCookie]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
        zIndex: {
          appBar: 1200,
          drawer: 1100,
        },
        components: {
          MuiDrawer: {
            styleOverrides: {
              root: {
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                },
              },
            },
          },
        },
      }),
    [mode]
  );
  return (
    <CookiesProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Router>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {children}
              </LocalizationProvider>
            </AuthProvider>
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CookiesProvider>
  );
}

export { AppProviders };
