import React from "react";

const NotFoundScreen = () => {
  return (
    <div>
      <h1>Not found</h1>
    </div>
  );
};

export default NotFoundScreen;
