import { auth } from "./firebase";

const FLEETMANAGER_BASE_URL_PROD =
  "https://fleetmanager-ddramqvvda-ew.a.run.app";
const USERMANAGER_BASE_URL_PROD = "https://usermanager-ddramqvvda-ew.a.run.app";

const FLEETMANAGER_BASE_URL_TEST =
  "https://fleetmanager-zth3oj5vda-ew.a.run.app";
const USERMANAGER_BASE_URL_TEST = "https://usermanager-zth3oj5vda-ew.a.run.app";

const FLEETMANAGER_BASE_URL_DEV = "http://localhost:8081";
const USERMANAGER_BASE_URL_DEV = "http://localhost:8080";

const fleetmanagerBaseURL = () => {
  if (process.env.NODE_ENV === "development") {
    return FLEETMANAGER_BASE_URL_DEV;
  }

  if (process.env.REACT_APP_BUILD_ENV === "PROD") {
    return FLEETMANAGER_BASE_URL_PROD;
  } else {
    return FLEETMANAGER_BASE_URL_TEST;
  }
};

const usermanagerBaseURL = () => {
  if (process.env.NODE_ENV === "development") {
    return USERMANAGER_BASE_URL_DEV;
  }

  if (process.env.REACT_APP_BUILD_ENV === "PROD") {
    return USERMANAGER_BASE_URL_PROD;
  } else {
    return USERMANAGER_BASE_URL_TEST;
  }
};
const throwIfHTTPError = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

const getToken = async () => await auth.currentUser.getIdToken(false);

const asJSON = (response) => {
  return response.json();
};

export const fleetmanagerFetcher = async (...args) => {
  const baseURL = fleetmanagerBaseURL();
  return await makeFetcher(baseURL)(...args);
};

export const userManagerFetcher = async (...args) => {
  const baseURL = usermanagerBaseURL();
  return await makeFetcher(baseURL)(...args);
};

const makeFetcher =
  (baseURL) =>
  async (...args) => {
    const [path] = args;
    const url = `${baseURL}${path}`;
    const token = await getToken();

    const res = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    if (!res.ok) {
      // Default error
      const error = new Error(`An error occurred while fetching ${path}`);

      // Attempt to decode into a more specific error message
      try {
        const errorData = await res.json();
        error.message = errorData.message;
      } catch (err) {}

      error.status = res.status;
      throw error;
    }

    return res.json();
  };

export const saveCustomer = async (customer) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  let url = `${baseURL}/customers/`;
  let method = "POST";

  if (customer.id) {
    method = "PATCH";
    url += customer.id;
  }

  return fetch(url, {
    method: method,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(customer),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const addServiceEntry = async (vehicleID, serviceEntry) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  let url = `${baseURL}/service_history/${vehicleID}`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(serviceEntry),
  }).then(throwIfHTTPError);
};

export const createPartsOrder = async (order) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  let url = `${baseURL}/part_orders`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(order),
  }).then(throwIfHTTPError);
};

export const saveVehicle = async (vehicle) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  let url = `${baseURL}/vehicles/`;
  let method = "POST";

  if (vehicle.id) {
    method = "PATCH";
    url += vehicle.id;
  }

  return fetch(url, {
    method: method,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(vehicle),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const addVehicleOwnership = async (customerID, vehicleID) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  let url = `${baseURL}/customers/${customerID}/vehicles/${vehicleID}`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  }).then(throwIfHTTPError);
};

export const updateUser = async (user) => {
  const baseURL = usermanagerBaseURL();
  const token = await getToken();
  const url = `${baseURL}/users/${user.id}`;

  return fetch(url, {
    method: "PATCH",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(user),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const createUser = async (user) => {
  const baseURL = usermanagerBaseURL();
  const token = await getToken();
  const url = `${baseURL}/users/`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(user),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const createDevice = async (name, publicKey) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  const url = `${baseURL}/hmi_devices/`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      name: name,
      rsa_public_key: publicKey,
    }),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const saveDeviceConfig = async (deviceID, deviceConfig) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  const url = `${baseURL}/hmi_devices/${deviceID}/`;

  return fetch(url, {
    method: "PATCH",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(deviceConfig),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const requestServiceTechnicianAccount = async (email, password) => {
  const baseURL = usermanagerBaseURL();
  const url = `${baseURL}/users/service-technician`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then(throwIfHTTPError)
    .then(asJSON);
};

export const acceptDevice = async (device) => {
  const baseURL = fleetmanagerBaseURL();
  const token = await getToken();
  const url = `${baseURL}/mender-devices/accept/${device.id}`;

  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      auth_sets: device.auth_sets,
    }),
  }).then(throwIfHTTPError);
};
