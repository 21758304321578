import React, { useEffect, useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";

export const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState({});
  const history = useHistory();

  useEffect(() => {
    const unsubsribe = auth.onIdTokenChanged((userAuth) => {
      setUser(userAuth);

      if (userAuth) {
        userAuth
          .getIdTokenResult()
          .then((idTokenResult) => {
            setRoles(idTokenResult.claims);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        history.push("/");
      }
    });
    return () => {
      unsubsribe();
    };
  }, [history]);

  return <AuthContext.Provider value={{ user, roles }} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
