import React from "react";
import moment from "moment";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";

import { CardAlert, CardTitle, CardSubTitle, CardSection } from "./lib";
import { useHMIDevice } from "../data/use-hmi-device";
import { Tooltip } from "@mui/material";

export const HMIDeviceState = ({ deviceID, title = "Device state" }) => {
  const [state, setState] = React.useState({
    version: "unknown",
    os: "unknown",
    os_image: "unknown",
  });
  const [stateUpdatedAt, setStateUpdatedAt] = React.useState("never");

  const { device, isLoading, error } = useHMIDevice(deviceID);

  React.useEffect(() => {
    try {
      setState(JSON.parse(atob(device.state.binaryData)));
    } catch {}
  }, [device]);

  React.useEffect(() => {
    try {
      setStateUpdatedAt(moment(device.state.updateTime).fromNow());
    } catch {}
  }, [device]);

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            `Updated ${stateUpdatedAt}`
          )}
        </CardSubTitle>
        {error && <CardAlert>{error.message}</CardAlert>}
        <div style={{ display: "flex" }}>
          <CardSection>
            <Typography color="textSecondary">HMI Version</Typography>
            <Typography variant="h5" component="h2">
              {isLoading ? <Skeleton variant="text" /> : state.version}
              &nbsp;
            </Typography>
          </CardSection>
          <CardSection>
            <Typography color="textSecondary">OS</Typography>
            <Tooltip
              title={
                isLoading ? (
                  <Skeleton variant="text" />
                ) : state.os_image !== "unknown" ? (
                  state.os_image
                ) : (
                  state.os
                )
              }
            >
              <Typography variant="h5" component="h2">
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : state.os_image !== "unknown" ? (
                  state.os_image
                ) : (
                  `${state.os.substring(0, 10)}...`
                )}
              </Typography>
            </Tooltip>
          </CardSection>
        </div>
      </CardContent>
    </Card>
  );
};
