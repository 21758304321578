import React from "react";
import { useAuth } from "./context/auth-context";
import { FullPageSpinner } from "./components/lib";
import NotFoundScreen from "./screens/not-found.jsx";

const AdminApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./app-admin")
);
const ServiceTechnicianApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./app-service-technician")
);
const UnauthenticatedApp = React.lazy(() => import("./app-unauthenticated"));

const ManufacturerApp = React.lazy(() => import("./app-manufacturer"));

const App = () => {
  const { user, roles } = useAuth();

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {(() => {
        if (!user) {
          return <UnauthenticatedApp />;
        }

        if (roles.admin) {
          return <AdminApp />;
        } else if (roles.serviceTechnician) {
          return <ServiceTechnicianApp />;
        } else if (roles.manufacturer) {
          return <ManufacturerApp />;
        }

        return <NotFoundScreen />;
      })()}
    </React.Suspense>
  );
};

export default App;
