export const validateVin = (vin) => {
  const result = {
    vin: vin,
    ok: false,
  };
  // if dev bike, return true
  if (isDevBike(vin)) {
    result.ok = true;
    result.message = "You're creating a dev bike!";
    result.variant = "NO1";
    result.name = "{your name} Dev Bike";
    return result;
  }

  // No dev bike, ok here we go...
  vin = escapeInvalidChars(vin);
  result.vin = vin;

  if (!validWMICode(vin)) {
    result.message = "VIN must start with 'YSH'";
    return result;
  }
  if (!validMakeType(vin)) {
    result.message = `Make/Type code must be '01' (Classic) or '02' (Scrambler), was ${getMakeType(
      vin
    )}`;
    return result;
  }
  if (!validLineEngine(vin)) {
    result.message = `Line/Engine code must be '00', '10'(S) or '20'(SE(L)), was ${getLineEngine(
      vin
    )}`;
    return result;
  }
  if (!validNetBrakeHP(vin)) {
    result.message = `Code for net brake hp must be 'A', was ${getNetBrake(
      vin
    )}`;
    return result;
  }
  // check digit is validated last, return info msg
  if (vin.length === 8) {
    result.message = "Enter the check digit";
    return result;
  }
  if (!validYear(vin)) {
    result.message = `Year code must be 'L' (2020), 'M' (2021) or 'N' (2022), was ${getYear(
      vin
    )}`;
    return result;
  }
  if (!validPlant(vin)) {
    result.message = `Plant code must be 'K', was ${getPlantCode(vin)}`;
    return result;
  }
  if (!validSerialNum(vin)) {
    result.message = `Serial number should be in format 'R[00000 - 99999]', got ${getSerialNumber(
      vin
    )}`;
    return result;
  }
  if (!validLength(vin)) {
    result.message = `Vin must be 17 characters long`;
    return result;
  }
  if (!validCheckDigit(vin)) {
    result.message = `Wrong check digit, got ${
      vin[8]
    }, should be ${getCheckDigit(vin)}`;
    return result;
  }

  // generate variant and name and generation (if possible) from vin
  let nameSuffix = getSerialNumber(vin).replace(/[R0]+/, "");
  result.ok = true;
  result.variant = getMakeType(vin) === "01" ? "NO1" : "SCRAMBLER";
  result.name =
    getMakeType(vin) === "01" ? `RVC${nameSuffix}` : `RVS${nameSuffix}`;
  result.gen = ["10", "20"].includes(getLineEngine(vin)) ? "2.0" : null;
  return result;
};

// getters
const getMakeType = (vin) => vin.substring(3, 5);
const getLineEngine = (vin) => vin.substring(5, 7);
const getNetBrake = (vin) => vin.charAt(7);
const getYear = (vin) => vin.charAt(9);
const getPlantCode = (vin) => vin.charAt(10);
const getSerialNumber = (vin) => vin.substring(11, 17);
// validators
const isDevBike = (vin) => vin.startsWith("YSH_");
const escapeInvalidChars = (vin) => vin.replace(/[^0-9A-Za-z]/g, "");
const validWMICode = (vin) => vin.startsWith("YSH");
const validMakeType = (vin) => ["01", "02"].includes(getMakeType(vin));
const validLineEngine = (vin) =>
  ["00", "10", "20"].includes(getLineEngine(vin));
const validNetBrakeHP = (vin) => getNetBrake(vin) === "A";
const validYear = (vin) => getYear(vin).match(/[L|M|N]+/) !== null;
const validPlant = (vin) => getPlantCode(vin) === "K";
const validSerialNum = (vin) =>
  getSerialNumber(vin).match(/([R])([0-9]){5}/) !== null;
const validLength = (vin) => vin.length === 17;
const validCheckDigit = (vin) => getCheckDigit(vin) === vin[8];
// check digit calculation
const getCheckDigit = (vin) => {
  var map = "0123456789X";
  var weights = "8765432X098765432";
  var sum = 0;
  for (var i = 0; i < 17; ++i)
    sum += transliterate(vin[i]) * map.indexOf(weights[i]);
  return map[sum % 11];
};

const transliterate = (c) => {
  return "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ".indexOf(c) % 10;
};

export const getVariantFromVin = (vin) => {
  let variant = vin.substring(3, 5);
  if (variant === "01") {
    return "NO1";
  } else if (variant === "02") {
    return "SCRAMBLER";
  }
  return null;
};

export const getWheelDiaFromVin = (vin) => {
  return getWheelDiaFromVariant(getVariantFromVin(vin));
};

const getWheelDiaFromVariant = (variant) => {
  if (variant === "NO1") {
    return 624;
  } else if (variant === "SCRAMBLER") {
    return 640;
  } else {
    return 0;
  }
};
