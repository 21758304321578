import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfigProd = {
  apiKey: "AIzaSyBd1F2T6AwvWmYcnJKeOnqYZLNTL2renDk",
  authDomain: "madeleine-ec74c.firebaseapp.com",
  databaseURL: "https://madeleine-ec74c.firebaseio.com",
  projectId: "madeleine-ec74c",
  storageBucket: "madeleine-ec74c.appspot.com",
  messagingSenderId: "436728144548",
  appId: "1:436728144548:web:54a1de16dd71eed45f7c31",
  measurementId: "G-CF4GDRR68B",
};

const firebaseConfigTest = {
  apiKey: "AIzaSyBQ_E_Vygh1zl9LOhhlYXcB2OP0GdvnH3c",
  authDomain: "madeleine-ec74c-test.firebaseapp.com",
  projectId: "madeleine-ec74c-test",
  storageBucket: "madeleine-ec74c-test.appspot.com",
  messagingSenderId: "122091123444",
  appId: "1:122091123444:web:668aec9662311a3c6964a1",
};

const getFirebaseConfig = () => {
  if (process.env.REACT_APP_BUILD_ENV === "PROD") {
    console.log("Running in PRODUCTION mode");
    return firebaseConfigProd;
  } else {
    console.log("Running in TEST mode");
    return firebaseConfigTest;
  }
};

const firebaseApp = initializeApp(getFirebaseConfig());
export const auth = getAuth(firebaseApp);

const storage = getStorage(firebaseApp);

export const storageRef = ref(storage);
export const serviceTechnicianStorageRef = ref(storage, "serviceTechnician");
export const complianceStorageRef = ref(storage, "compliance");
export const releaseStorageRef = ref(storage, "release");
